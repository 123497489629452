import React from "react"
import Seo from "../components/seo"

const Terms = () => (
  <>
    <Seo title="Terms & Conditions" />

    <div className="px-8 max-w-screen-lg mx-auto my-12">
      <h1 className="font-sans text-4xl text-primary tracking-wider">
        Terms & Conditions
      </h1>

      <div className="prose text-primary">
        <h2>About Art of Science</h2>

        <p>
          Art of Science Chip Art NFTs created and sold by Disruptify Inc, a
          company based in Ras al Khaimah, UAE, under the terms and conditions
          outlined on this page.
        </p>
        <p>
          Art of Science Chip Art NFTs are a set of 2,750 digital artworks
          (Non-Fungible Tokens, or “NFTs”) that operate on the Ethereum
          blockchain. An individual token is referred to here as a “AOS”. This
          website, https://artof.science, provides a user interface for
          participants to purchase NFTs, as well as to interact with any NFTs
          they own. During the sales period, individuals will be able to
          purchase AOSs on this website at a price of 0.07 ETH each using an
          electronic Ethereum wallet. Transactions made via the website and
          associated Ethereum smart contracts are managed and confirmed via the
          Ethereum blockchain. We do not cover the transaction fee of the
          Ethereum network (“Gas Fee”) and will not be liable for the acts or
          omissions of any third parties, nor will we be liable for any damage
          that you may suffer as a result of your transactions or any other
          interaction with any third parties including MetaMask, web browsers
          such as Google Chrome, and the Ethereum network. The Art of Science
          website and related services are provided “as is” and “as available”
          without any type of warranty. Participants acknowledge that, given
          that the Art of Science smart contract operates on the Ethereum
          network, there is no mechanism to undo, reverse, or reinstate any
          transactions. The actual AOSs are too large to be stored on the
          blockchain, so we took a hash of the composite image of all AOS NFTs
          and embedded it into the Smart Contract. Initially all data will be
          hosted on a private secure server to prevent the secrets of
          “unrevealed” AOSs from being discovered; 6 months after first mint (or
          the last AOS version is uncovered, whichever is sooner) we will
          transfer all data to IPFS. We will support such storage until at least
          December 31, 2031.
        </p>

        <h2>Your Responsibilities</h2>
        <p>
          By using the website and related services, you accept sole
          responsibility for:
        </p>

        <ul>
          <li>All transactions involving Art of Science collectables</li>
          <li>
            The safety and maintenance of your Ethereum wallet and the private
            key to access it
          </li>
          <li>
            Checking the validity of transactions and contracts from this
            website prior to approval
          </li>
          <li>
            Downloading your NFT Art on or before December 31, 2031, after which
            we may no longer support the storage of the actual NFT Art.
          </li>
          <li>
            Determining what, if any, taxes apply to any transactions related to
            the Art of Science project, which may vary for different owners
            based on their place of residence for tax purposes
          </li>
          <li>Paying any taxes due to the relevant authorities</li>
        </ul>

        <h2>Participant Ownership and Rights</h2>
        <ul>
          <li>
            <b>Participants own their NFTs.</b> Each AOS is a non-fungible token
            on the Ethereum network. Token ownership is completely managed by
            our smart contract and the Ethereum blockchain. We are unable to
            take over, freeze, or change the ownership of any of the AOSs.
          </li>
          <li>
            <b>Personal use.</b> As long as you comply with these terms and
            conditions, Disruptify Inc. grants you a worldwide, royalty-free
            license to use, copy, and display the purchased Art, as well as any
            extensions of it that you create, solely for the following purposes:
            <ul>
              <li>for your personal, non-commercial use;</li>
              <li>
                as part of a marketplace that allows the buying and selling of
                your Art of Science NFT, as long as the marketplace
                cryptographically verifies each NFT owner’s rights to display
                the Art to ensure that only the actual owner can display the
                Art; or
              </li>
              <li>
                as part of a third party website or application that allows the
                inclusion, involvement, or participation of your Art of Science
                NFT, so long as that website or application cryptographically
                verifies each participant’s rights to display the Art for their
                Art of Science NFT to ensure that only the actual owner can
                display the Art, and provided that the Art is no longer visible
                once the owner of the Art of Science NFT leaves the website or
                application.
              </li>
            </ul>
          </li>
          <li>
            <b>Restrictions.</b> You agree that you may not, nor permit any
            third party to do or attempt to do any of the foregoing without
            Disruptify Inc’s express prior written consent in each case:
            <ul>
              <li>
                use the purchased Art to advertise, market, or sell any third
                party product or service;
              </li>
              <li>
                use the purchasers Art in connection with images, videos, or
                other forms of media that depict hatred, intolerance, violence,
                cruelty, or anything else that could reasonably be found to
                constitute hate speech or otherwise infringe upon the rights of
                others;
              </li>
              <li>
                sell, distribute for commercial gain (including, without
                limitation, giving away in the hopes of eventual commercial
                gain), or otherwise commercialize merchandise that includes,
                contains, or consists of the purchased Art, except as expressly
                permitted in these Terms;
              </li>
              <li>
                attempt to trademark, copyright, or otherwise acquire additional
                intellectual property rights in or to the purchased Art.
              </li>
            </ul>
          </li>
        </ul>

        <h2>Creator Rights</h2>
        <p>
          Disruptify Inc. shall retain the rights to, including but not limited
          to, reproduce, adapt, modify, use, perform, display, publish,
          distribute, sell, and duplicate the purchased Art and any derivative
          works thereof, in whole or in part.
        </p>

        <h2>Value</h2>
        <p>
          AOSs are sold at their assumed market value at the time of sale, with
          no expectation of future increases in value. The prices of collectible
          blockchain assets and cryptocurrencies are extremely volatile and
          subjective, and individual assets such as Art of Science NFTs have no
          inherent or intrinsic value. Further risk is introduced by the
          regulatory uncertainty which surrounds crypto assets including NFTs
          and cryptocurrencies, changes in which could significantly impact the
          utility and value of the Ethereum blockchain and the ecosystem built
          around it. By purchasing a AOS, you accept these risks and acknowledge
          that your AOS may not retain its original value.
        </p>
      </div>
    </div>
  </>
)

export default Terms
